import React, {useEffect} from 'react';
import {Link as RouterLink, Outlet, useLocation, useNavigate} from "react-router-dom";
import logo from "../../components/image/logo.svg";
import {Box, CircularProgress, Grid2, Typography} from "@mui/material";
import ChildrenWithBackpacksTalkingSVG from "../../components/image/svg/ChildrenWithBackpacksTalkingSVG";
import {SxProps} from "@mui/system";
import {useSelector} from "react-redux";
import {isUserAuthenticatedSelector} from "../../state/base/auth/AuthSelectors";

export default function Auth() {
  const location = useLocation();
  const navigate = useNavigate();

  // Selectors
  const isUserAuthenticated = useSelector(isUserAuthenticatedSelector());

  // Effects
  useEffect(() => {
    if (isUserAuthenticated) {
      const fromLocation = location.state?.from?.pathname;
      navigate(fromLocation ?? '/');
    }
  }, [isUserAuthenticated, navigate, location]);

  return isUserAuthenticated == null ?
    <Box sx={{height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
      <CircularProgress />
    </Box>
    :
    <Box sx={sxStyles.rootContainer}>
      {/* Header - Always visible */}
      <Box sx={sxStyles.headerContainer}>
        <RouterLink to="/" style={styles.logoRouterLink}>
          <img src={logo}
               style={{height: '2rem'}}
               alt="logo"/>
          <Typography style={styles.logoText} color="textSecondary" variant="h2" fontWeight="500" sx={{ ml: 1 }}>
            KidsLog
          </Typography>
        </RouterLink>
      </Box>

      {/* Main content */}
      <Box sx={sxStyles.contentWrapper}>
        <Grid2 container
              spacing={0}
              sx={sxStyles.container}>
          <Grid2 size={{xs:12, sm:12, lg:6}}
                sx={{
                  background: '#ffffff',
                  display: { xs: 'none', lg: 'flex' },
                  alignItems: 'center',
                  justifyContent: 'center',
                  height: '100%'
                }}>
            <ChildrenWithBackpacksTalkingSVG style={styles.firstHalfImage}/>
          </Grid2>
          <Grid2 size={{xs:12, sm:12, lg:6}} display="flex" alignItems="center" sx={{height: '100%'}}>
            <Outlet />
          </Grid2>
        </Grid2>
      </Box>
    </Box>;
}

const sxStyles: { [key: string]: SxProps } = {
  container: {
    width: '100%',
    height: '100%',
  },
  contentWrapper: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: '35px', // Half of header height to offset centering
    zIndex: 1,
  },
  headerContainer: {
    display: 'flex',
    px: 5,
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    height: '70px',
    zIndex: 1000,
    '&::before': {
      content: '""',
      position: 'absolute',
      left: 0,
      top: 0,
      width: { xs: '100%', lg: '50%' },
      height: '100%',
      backgroundColor: '#ffffff',
      zIndex: -1
    },
    '&::after': {
      content: '""',
      position: 'absolute',
      right: 0,
      top: 0,
      width: { xs: '0%', lg: '50%' },
      height: '100%',
      backgroundColor: 'transparent',
      zIndex: -1
    }
  },
  rootContainer: {
    height: '100vh',
    overflow: 'hidden',
    position: 'relative',
  }
}
const styles: { [key: string]: React.CSSProperties } = {
  logoRouterLink: {
    display: 'flex',
    height: '100%',
    flexDirection: 'row',
    textDecoration: 'none',
    alignItems: 'center',
    color: '#000',
  },
  logoText: {
    fontFamily: 'Clash Grotesk',
    color: '#2B353F',
    marginLeft: 10,
  },
  firstHalfImage: {
    height: 'auto',
    width: '90%',
    maxWidth: '812px',
    maxHeight: 'calc(100vh - 140px)',
    objectFit: 'contain',
  }
}
