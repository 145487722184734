import {catchError, of} from 'rxjs';
import {doPost} from "./AxiosKLBackend";
import {map} from "rxjs/operators";
import {AuthActionResponse} from "../../../state/base/auth/AuthActions";


export function sendResetPasswordMail$(email: string) {
  return doPost('public/sendResetPasswordMail', {}, email, 'text/plain')
    .pipe(
      map((): AuthActionResponse => ({ success: true })),
      catchError(e => of({ success: false, error: e.message } as AuthActionResponse))
    );
}
