import React, {useEffect} from "react";
import {Route, Routes} from "react-router-dom";
import HomePage from "../views/home/Home";
import About from "../views/about/About";
import DemoRequest from "../views/demo-request/DemoRequest";
import Contact from "../views/contact/Contact";
import PrivacyPolicy from "../views/privacy-policy/PrivacyPolicy";
import Auth from "../views/auth/Auth";
import Login from "../views/auth/Login";
import BasePage from "../views/base/BasePage";
import AccountDeletion from "../views/account/AccountDeletion";
import ProtectedRoute from "./ProtectedRoute";
import {User} from "firebase/auth";
import {useDispatch, useSelector} from "react-redux";
import {currentUserSelector} from "../state/base/auth/AuthSelectors";
import {loadUser} from "../state/base/auth/AuthActions";
import {loadProfile} from "../state/base/profile/ProfileActions";
import {Dispatch} from "redux";
import Register from "../views/auth/Register";
import AppDownload from "../views/app-download/AppDownload";
import AdminBasePage from "../views/admin/base/AdminBasePage";
import AdminUsers from "../views/admin/admin-users/AdminUsers";
import AdminOverview from "../views/admin/overview/AdminOverview";
import AdminOrganizations from "../views/admin/organizations/AdminOrganizations";
import AdminPricingPlanList from "../views/admin/pricing/AdminPricingPlanList";
import AdminPricingPlanDetails from "../views/admin/pricing/AdminPricingPlanDetails";
import PasswordReset from "../views/auth/PasswordReset";


export default function AppRoutes(){
  const dispatch: Dispatch<any> = useDispatch();

  // Selectors
  const user: User = useSelector(currentUserSelector());

  // Effects
  useEffect(() => {
    if (!user?.uid) {
      dispatch(loadUser());
    } else {
      dispatch(loadProfile(user.uid));
    }
  }, [user, dispatch]);

  return (
    <Routes>
      <Route path={'/'} element={<BasePage/>}>
        <Route index element={<HomePage/>} />
        <Route path='about' element={<About/>} />
        <Route path='demo-request' element={<DemoRequest/>} />
        <Route path='contact' element={<Contact/>} />
        <Route path='privacy-policy' element={<PrivacyPolicy/>} />
      </Route>
      <Route path={'/auth'} element={<Auth/>}>
        {/*<Route path='login' element={<Login/>} />*/}
        {/*<Route path='register' element={<Register/>} />*/}
        <Route path='password-reset' element={<PasswordReset/>} />
      </Route>
      <Route path={'/account/delete'} element={
        <ProtectedRoute>
          <AccountDeletion/>
        </ProtectedRoute>
      }/>
      <Route path={'/app-download'} element={<AppDownload/>}/>
      {/*<Route path={'/admin'} element={*/}
      {/*  <ProtectedRoute>*/}
      {/*    <AdminBasePage/>*/}
      {/*  </ProtectedRoute>*/}
      {/*}>*/}
      {/*  <Route path='' element={<AdminOverview/>} />*/}
      {/*  <Route path='users' element={<AdminUsers/>} />*/}
      {/*  <Route path='organizations' element={<AdminOrganizations/>} />*/}
      {/*  <Route path='pricing'>*/}
      {/*    <Route path='' element={<AdminPricingPlanList/>} />*/}
      {/*    <Route path=':planType' element={<AdminPricingPlanDetails/>} />*/}
      {/*  </Route>*/}
      {/*</Route>*/}
    </Routes>
  )
}
