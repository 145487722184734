import React, {useState} from 'react';
import {Box, Button, TextField, Typography, CircularProgress} from "@mui/material";
import {useSearchParams} from "react-router-dom";
import {SxProps} from "@mui/system";
import {resetPassword$} from "../../state/base/auth/AuthActions";
import {AuthActionResponse} from "../../state/base/auth/AuthActions";
import {sendResetPasswordMail$} from "../../domain/common/repo/PublicRepo";

export default function PasswordReset() {
  const [searchParams] = useSearchParams();
  const code = searchParams.get('code');

  // State
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  if (code) {
    // Show password reset form
    return (
      <Box sx={sxStyles.container}>
        <Box sx={sxStyles.secondContainer}>
          <Typography fontWeight="700" variant="h2">
            Set New Password
          </Typography>

          {error && (
            <Typography color="error" sx={{ mt: 2 }}>
              {error}
            </Typography>
          )}

          {success && (
            <Typography color="success.main" sx={{ mt: 2 }}>
              {success}
              {/*<NavLink to="/auth/login" style={{ marginLeft: 8 }}>*/}
              {/*  Sign in*/}
              {/*</NavLink>*/}
            </Typography>
          )}

          <TextField
            label="New Password"
            variant="outlined"
            sx={{width: '100%', marginTop: 3}}
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />

          <TextField
            label="Confirm Password"
            variant="outlined"
            sx={{width: '100%', marginTop: 3}}
            type="password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
          />

          <Button
            color="secondary"
            variant="contained"
            size="large"
            fullWidth
            onClick={handlePasswordReset}
            sx={{marginTop: 5, padding: 1.5}}
          >
            Reset Password
          </Button>
        </Box>
      </Box>
    );
  }

  // Show email form
  return (
    <Box sx={sxStyles.container}>
      <Box sx={sxStyles.secondContainer}>
        <Typography fontWeight="700" variant="h2">
          Reset Password
        </Typography>
        {/*<Box display="flex" alignItems="center">*/}
        {/*  <Typography color="textSecondary"*/}
        {/*            variant="h6"*/}
        {/*            fontWeight="500"*/}
        {/*            sx={{ mr: 1 }}>*/}
        {/*    Remember your password?*/}
        {/*  </Typography>*/}
        {/*  <Typography component={NavLink}*/}
        {/*            to="/auth/login"*/}
        {/*            fontWeight="500"*/}
        {/*            sx={sxStyles.createAnAccountLink}>*/}
        {/*    Sign In*/}
        {/*  </Typography>*/}
        {/*</Box>*/}

        {error && (
          <Typography color="error" sx={{ mt: 2 }}>
            {error}
          </Typography>
        )}

        {success && (
          <Typography color="success.main" sx={{ mt: 2 }}>
            {success}
          </Typography>
        )}

        <Typography color="textSecondary"
                  variant="body1"
                  sx={{ mt: 3 }}>
          Enter your email address and we'll send you instructions to reset your password.
        </Typography>

        <TextField
          label="Email Address"
          variant="outlined"
          sx={{width: '100%', marginTop: 3}}
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />

        <Button
          color="secondary"
          variant="contained"
          size="large"
          fullWidth
          onClick={handleSendResetEmail}
          disabled={isLoading}
          sx={{marginTop: 5, padding: 1.5}}
        >
          {isLoading ? (
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <CircularProgress size={24} color="inherit" sx={{ mr: 1 }} />
              Sending...
            </Box>
          ) : (
            'Send Reset Link'
          )}
        </Button>
      </Box>
    </Box>
  );

  function handleSendResetEmail() {
    setIsLoading(true);
    sendResetPasswordMail$(email).subscribe({
      next: (response: AuthActionResponse) => {
        setIsLoading(false);
        if (response.success) {
          setSuccess('Reset link has been sent to your email address.');
          setError('');
          setEmail('');
        } else {
          setError(response.error || 'An error occurred');
          setSuccess('');
        }
      },
      error: (err) => {
        setIsLoading(false);
        setError('Failed to send reset link. Please try again.');
        setSuccess('');
      }
    });
  }

  function handlePasswordReset() {
    if (password !== confirmPassword) {
      setError('Passwords do not match');
      return;
    }

    if (!code) {
      setError('Invalid password reset code');
      return;
    }

    resetPassword$(code, password).subscribe({
      next: (response: AuthActionResponse) => {
        if (response.success) {
          setSuccess('Password has been reset successfully.');
          setError('');
          setPassword('');
          setConfirmPassword('');
        } else {
          setError(response.error || 'An error occurred');
          setSuccess('');
        }
      }
    });
  }
}

const sxStyles: { [key: string]: SxProps } = {
  container: {
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  secondContainer: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    width: {
      xs: '80%',  // 80% width on mobile devices
      sm: '65%',  // 65% width on tablet devices
      md: '50%'   // 50% width on desktop and larger
    }
  },
  createAnAccountLink: {
    display: 'block',
    textDecoration: 'none',
    color: 'primary.main',
  },
}
